
export default {
  props: {
    src: {
      type: String,
      required: true,
      default: '',
    },
    alt: {
      type: String,
      required: false,
      default: `Couldn't load image`,
    },
  },
  computed: {
    path() {
      try {
        return require(`~/assets/images/shapes/${this.src}.svg`)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('invalid image source:', this.src)
        return this.alt
      }
    },
  },
}
