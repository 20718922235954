
import themeMixin from '~/mixins/theme'
import { formatDate } from '~/utils/formatters'

export default {
  name: 'MenuWidgetLarge',
  mixins: [themeMixin],
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      formatDate,
    }
  },
}
