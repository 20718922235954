export const formatDate = (d) => {
  let date = new Date(d)
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  date = new Date(date.getTime() + userTimezoneOffset)
  const options = { month: 'long', day: 'numeric', year: 'numeric' }

  return date.toLocaleDateString('en-us', options)
}

export const formatDateYearOnly = (d) => {
  let date = new Date(d)
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  date = new Date(date.getTime() + userTimezoneOffset)
  const options = { year: 'numeric' }

  return date.toLocaleDateString('en-us', options)
}

export const formatDateWithMonthAndYear = (d) => {
  let date = new Date(d)
  const options = { month: 'long', year: 'numeric' }
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  date = new Date(date.getTime() + userTimezoneOffset)

  return date.toLocaleDateString('en-us', options)
}

export const formatDateWithMonthAndDay = (d) => {
  let date = new Date(d)
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  date = new Date(date.getTime() + userTimezoneOffset)

  const options = { day: 'numeric', month: 'short' }

  return date.toLocaleDateString('en-gb', options)
}
