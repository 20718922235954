
import MenuWidgetLarge from './Large'
import MenuWidgetSmall from './Small'
import MenuWidgetSmallCompetitionLogo from './SmallCompetitionLogo'

export default {
  name: 'MenuWidget',
  components: {
    MenuWidgetLarge,
    MenuWidgetSmall,
    MenuWidgetSmallCompetitionLogo,
  },
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    component() {
      const mappings = {
        default: 'MenuWidgetLarge',
        small: 'MenuWidgetSmall',
        smallCompetitionWithLogo: 'MenuWidgetSmallCompetitionLogo',
      }
      return mappings[this.variation]
    },
    variation() {
      return this.slice.variation
    },
  },
}
